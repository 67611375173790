<template>
<div>

    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LOS CONTROLES ANTISOBORNO:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-tasks fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaControlesAntisoborno.length}}</span>
                            <br>
                            <span class="text-muted">Controles antisoborno registrados</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de controles antisoborno</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col v-if="checkPermissions('017-37001-COA','c') == 1" cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalNuevoControl = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Agregar control actual</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5">Controles antisoborno registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaControlesAntisoborno" :fields="cabecera" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #table-colgroup="cabecera">
                                    <col v-for="field in cabecera.fields" :key="field.key" :style="{width:field.key === 'numeroControl'? '10%': field.key === 'descripcionControl'? '30%': field.key === 'subControles'? '47%': field.key === 'opciones'? '8%': '5%',}" />
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('017-37001-COA','u') == 1" @click="abrirModalActualizarControl(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('017-37001-COA','d') == 1" @click="eliminarRiesgo(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(subcontroles)="param">
                                    {{'* '+JSON.parse(param.item.listaControlesDetalle).map((x) => x.subControl).join(' * ')}}
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal size="lg" color="primario" :show.sync="modalNuevoControl">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo control antisoborno</span>
            </h6>
            <CButtonClose @click="modalNuevoControl = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(nuevoControlAntisoborno)">
                <b-row>
                    <!-- <b-col md="4">
                        <validation-provider name="número de control" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de control:" class="mb-2">
                                <b-form-select v-model="datosControl.numeroControl" :options="listaNumerosControles" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col md="4">
                        <validation-provider name="número de control" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de control:" class="mb-2">
                                <b-form-input v-model="datosControl.numeroControl" :state="getValidationState(validationContext)" placeholder="Ingrese el número de control"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="8">
                        <validation-provider name="descripción del control" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción del control:" class="mb-2">
                                <b-form-input v-model="datosControl.descripcionControl" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del control"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <CButton size="sm" color="dark" class="float-right" @click="agregarSubControl()">
                            <i class="fas fa-plus fa-sm"></i> Agregar sub control
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosControl.listaControlesDetalle" :fields="campoPersonas" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(subControl)="row">
                                <b-form-input placeholder="Ingrese el sub control" v-model="row.item.subControl"></b-form-input>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="datosControl.listaControlesDetalle.length>1" @click="eliminarControl(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalNuevoControl = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal size="lg" color="primario" :show.sync="modalActualizarControl">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Actualizar control antisoborno </span>
            </h6>
            <CButtonClose @click="modalActualizarControl = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarControlAntisoborno)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="número de control" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de control:" class="mb-2">
                                <b-form-input v-model="datosControlActualizar.numeroControl" :state="getValidationState(validationContext)" placeholder="Ingrese el número de control"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <!-- <b-col md="4">
                        <validation-provider name="número de control" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Número de control:" class="mb-2">
                                <b-form-select v-model="datosControlActualizar.numeroControl" :options="listaNumerosControles" :state="getValidationState(validationContext)">
                                    <template v-slot:first>
                                        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col> -->
                    <b-col md="8">
                        <validation-provider name="descripción del control" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Descripción del control:" class="mb-2">
                                <b-form-input v-model="datosControlActualizar.descripcionControl" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción del control"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <CButton size="sm" color="dark" class="float-right" @click="agregarSubControlActualizar()">
                            <i class="fas fa-plus fa-sm"></i> Agregar sub control
                        </CButton>
                    </b-col>
                    <b-col md="12" class="mt-2">
                        <b-table :items="datosControlActualizar.listaControlesDetalle" :fields="campoPersonas" class="table-custom" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección.">
                            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                            <template v-slot:cell(subControl)="row">
                                <b-form-input placeholder="Ingrese el sub control" v-model="row.item.subControl"></b-form-input>
                            </template>
                            <template #cell(acciones)="param">
                                <b-button v-if="datosControlActualizar.listaControlesDetalle.length>1" @click="eliminarControlActualizar(param)" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </template>

                        </b-table>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarControl = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import {
    uuid
} from 'vue-uuid';

export default {
    components: {

    },
    data() {
        return {
            uuid: '',
            modalNuevoControl: false,
            modalActualizarControl: false,

            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            cabecera: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "numeroControl",
                    label: "Número de control",
                    class: "text-center",
                },
                {
                    key: "descripcionControl",
                    label: "Descripción del control",
                    class: "text-center",
                },
                {
                    key: "subControles",
                    label: "Sub controles",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            campoPersonas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "subControl",
                    label: "Sub Controles",
                    class: "text-center",
                },
                {
                    key: "acciones",
                    label: '',
                    class: "text-center"
                }
            ],
            listaControlesAntisoborno: [],
            datosControl: {
                idCliente: '',
                numeroControl: null,
                descripcionControl: '',
                listaControlesDetalle: [{
                    idSubControl: uuid.v4(),
                    subControl: '',
                }],
                ejercicio: JSON.parse(localStorage.settings).yExcs,
            },
            datosControlActualizar: {
                idRiesgo: '',
                idCliente: '',
                numeroControl: null,
                descripcionControl: '',
                listaControlesDetalle: [],
                ejercicio: '',
            },
            disabled: false,
            /* listaNumerosControles: [{
                    value: '8.1',
                    text: '8.1'
                },
                {
                    value: '8.2',
                    text: '8.2'
                },
                {
                    value: '8.3',
                    text: '8.3'
                },
                {
                    value: '8.4',
                    text: '8.4'
                },
                {
                    value: '8.5',
                    text: '8.5'
                },
                {
                    value: '8.6',
                    text: '8.6'
                },
                {
                    value: '8.7',
                    text: '8.7'
                },
                {
                    value: '8.8',
                    text: '8.8'
                },
            ] */
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        agregarSubControl() {
            let me = this;
            me.uuid = uuid.v4();
            me.datosControl.listaControlesDetalle.push({
                idSubControl: me.uuid,
                subControl: '',
            })
        },
        eliminarControl(param) {
            let me = this;
            me.datosControl.listaControlesDetalle.splice(param.index, 1)

        },
        agregarSubControlActualizar() {
            let me = this;
            me.uuid = uuid.v4();
            me.datosControlActualizar.listaControlesDetalle.push({
                idSubControl: me.uuid,
                subControl: '',
            })
        },
        eliminarControlActualizar(param) {
            let me = this;
            me.datosControlActualizar.listaControlesDetalle.splice(param.index, 1)

        },
        listarControlesAntisoborno() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-controles-antisoborno-sga", {
                        params: {
                            idCliente: me.datosControl.idCliente,
                            ejercicio: JSON.parse(localStorage.settings).yExcs,
                        },
                    }
                )
                .then(function (response) {
                    me.listaControlesAntisoborno = response.data
                    me.totalRows = me.listaControlesAntisoborno.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        nuevoControlAntisoborno() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-control-antisoborno-sga",
                    me.datosControl, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarControlesAntisoborno();
                    me.modalNuevoControl = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        actualizarControlAntisoborno() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-control-antisoborno-sga",
                    me.datosControlActualizar, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarControlesAntisoborno();
                    me.modalActualizarControl = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarControl(param) {
            let me = this;

            me.datosControlActualizar.idControlAntisoborno = param.item.idControlAntisoborno;
            me.datosControlActualizar.idCliente = param.item.idCliente;
            me.datosControlActualizar.numeroControl = param.item.numeroControl;
            me.datosControlActualizar.descripcionControl = param.item.descripcionControl;
            me.datosControlActualizar.listaControlesDetalle = JSON.parse(param.item.listaControlesDetalle);

            me.modalActualizarControl = true;
        },
        resetModalNuevoControl() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosControl.listaControlesDetalle = [];
            this.datosControl.numeroControl = null;
            this.datosControl.descripcionControl = '';
            this.datosControl.listaControlesDetalle = [{
                idSubControl: this.uuid,
                subControl: '',
            }];
        },
        resetModalActualizarControl() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosControlActualizar.listaControlesDetalle = [];
        },
        eliminarRiesgo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el control?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-control-antisoborno-sga", {
                            idControlAntisoborno: param.item.idControlAntisoborno,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarControlesAntisoborno();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedForm2() {
            return this.$refs.observer2;
        },
    },
    watch: {
        modalNuevoControl: function (val) {
            if (val == false) {
                this.resetModalNuevoControl();
            }
        },
        modalActualizarControl: function (val) {
            if (val == false) {
                this.resetModalActualizarControl();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosControl.idCliente = user.uidClient;
            this.listarControlesAntisoborno();
        }

    }

}
</script>
